<template>
    <h1>Login</h1>
    <form @submit.prevent="submitForm()" class="center" style="width: 22rem" v-if="!loggingIn">
        <input type="text" name="username" v-model="username" placeholder="username"/>
        <input type="password" name="password" v-model="password" placeholder="password"/>
        <input type="submit" value="Login" />
    </form>
    <pulse-loader :loading="loading" color="#386bc0" :size="size" v-if="loggingIn"></pulse-loader>
</template>

<script>

import {ref} from 'vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// import login from "@/components/Login.vue"

export default {
    components: {
        PulseLoader 
    },
    emits: ['login'],
    setup(props, {emit}) {
        const username = ref('')
        const password = ref('')
        const loggingIn = ref(false);

        const submitForm = () => {
            // alert(username.value)
            // alert(password.value)
            loggingIn.value = true;

            const params = new URLSearchParams();
            params.append('username', username.value);
            params.append('password', password.value);

            window.axios.post(
                process.env.VUE_APP_API_URL + '/login',
                params
            )
            .then(response => {
              console.log('axios Login.vue')
              console.log(response.data.access_token);
              if (response.data.access_token.length) {
                
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token // for all requests
                emit('login', response.data.access_token);
              } else {
                loggingIn.value = false;
              }
            })
            .catch(() => {
                loggingIn.value = false;
            })

            return false;
        }

        return {
            submitForm,
            username,
            password,
            loggingIn
        }
    }
}
</script>