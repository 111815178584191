import { createApp } from 'vue'
import { createRouter,createWebHistory} from 'vue-router'
import App from './App.vue'


import "./scss/styles.scss";

//import '@fortawesome/fontawesome-free/css/all.css'
//import '@fortawesome/fontawesome-free/js/all.js'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import './registerServiceWorker'

library.add(fas);

window.axios = require('axios');

// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    {
      path: "/",
      name: "index",
      component: () => import("@/components/Series.vue"),
    },
    {
      path: "/podcasts",
      name: "podcasts",
      component: () => import("@/components/Podcasts.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/components/About.vue"),
    },
    {
      path: "/tonies",
      name: "tonies",
      component: () => import("@/components/Tonies.vue"),
    },
    {
      path: "/tonie/:tonieId",
      name: "tonie",
      component: () => import("@/components/Tonie.vue"),
    },
    {
      path: "/episodes/:serieId",
      name: "episodes",
      component: () => import("@/components/Episodes.vue"),
    },
    {
      path: "/episode/:episodeId",
      name: "episode",
      component: () => import("@/components/Episode.vue"),
    },
    {
      path: "/episode/:episodeId/upload",
      name: "episodeUpload",
      component: () => import("@/components/EpisodeUpload.vue"),
    },
  ];

const router = createRouter({
// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(process.env.BASE_URL),
    routes: routes, // short for `routes: routes`
})

console.log(router)

// createApp(App).mount('#app')

const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

