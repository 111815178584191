<template>
    <div  v-if="!loginRequired">
      <router-link to="/">Index</router-link> | 
      <router-link to="/podcasts">Podcasts</router-link> | 
      <router-link to="/tonies">Kreativ-Tonies</router-link> | 
      <router-link to="/about">About</router-link> |
      <a href="#" @click="scan()">Scannen</a> |
      <!-- <a href="#" @click="scan_podcasts()">Scannen Podcasts</a> | -->
      <a href="#" @click="logout()">Logout</a>

      <router-view  :series="series" :podcasts="podcasts" :key="rerender"></router-view>
    </div>

    <login2 v-if="loginRequired" @login="login"></login2>
</template>


<script>

import {ref, onMounted} from 'vue'
import login2 from "@/components/Login2.vue"


export default {
    components: {
      login2
    },
    setup() {
        const series = ref([]);
        const podcasts = ref([]);
        const rerender = ref(false)
        const loginRequired = ref(true)

        const logout = () => {
          window.axios.get(process.env.VUE_APP_API_URL + '/logout')
            .then(() => {
                loginRequired.value = true;
            })
        }

        const scan = async() => {
          await window.axios.get(process.env.VUE_APP_API_URL + '/scan')
            .then(() => {
              console.log('scan for new content');
            })
        }

        const scan_podcasts = async() => {
          await window.axios.get(process.env.VUE_APP_API_URL + '/scan_podcasts')
            .then(() => {
              console.log('scan for new content');
            })
        }

        const login = async() => {
          await window.axios.get(process.env.VUE_APP_API_URL + '/series')
            .then(response => {
              console.log('axios App.vue')
              if (response.data == "Login required") {
                loginRequired.value = true;
              } else {
                series.value = response.data
                rerender.value = true;
                loginRequired.value = false;
              }
            })
          await window.axios.get(process.env.VUE_APP_API_URL + '/podcasts')
            .then(response => {
              console.log('axios App.vue')
              if (response.data == "Login required") {
                loginRequired.value = true;
              } else {
                podcasts.value = response.data
                rerender.value = true;
                loginRequired.value = false;
              }
            })
        }

        onMounted(async() => {
            console.log('onMounted App.vue')
            login();
        });

        return {
          series,
          podcasts,
          rerender,
          loginRequired,
          logout,
          login,
          scan,
          scan_podcasts
        }
    }
}
</script>

